<template>
  <div class="result">
      <img src="../assets/img/resultRealOk.png">
      <p>认证成功！</p>
      <div class="resultReal">
        <router-link :to="{path:'signList'}" class="button">去签约</router-link>
        <router-link :to="{path:'my'}" class="button">完成</router-link>
      </div>
  </div >
</template>
<script>
  export default {
    data() {
      return {
      };
    },
    created: function () {
    },
    methods: {
     
    }
  };
</script>

<style lang="scss">
.result{
  text-align: center;
   p{margin-top:1rem;font-size:1rem}
   img{width: 20%;margin-top: 8rem;}
   .resultReal{
     margin-top:3rem;
     a {
      display: inline-block;
      width: 40%;
      margin-left: 10px;
      margin-right: 10px;
      height: 2.5rem;
      line-height: 2.5rem;
    }
   }
}
</style>
